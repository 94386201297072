import { RouterModule, Routes} from '@angular/router';
import { NgModule, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

const routes: Routes = [
    {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        resolve: {
            // wait for translations to load
            ready: () => inject(TranslocoService).selectTranslate('common.languages.en')
        }
    },
    { path: '', redirectTo: 'pages', pathMatch: 'full' },
    { path: '**', redirectTo: 'pages' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
