import { Component } from '@angular/core';

@Component({
    selector: 'liq-app',
    template: `
        <router-outlet></router-outlet>
        <liq-doc360></liq-doc360>
    `
})
export class AppComponent {}
