import { NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { A11yModule } from '@angular/cdk/a11y';
import { MatSelectModule } from '@angular/material/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TokenInterceptor } from './token.interceptor';
import { CommonDirectivesModule } from './common/directives/commonDirectives.module';
import { MaterialModule } from './common/module/material.modules';
import { NgxDaterangepickerMd } from './common/components/daterangepicker/daterangepicker.module';
import { TranslocoRootModule } from './common/module/transloco.module';
import { LibraryIQToastComponent } from './common/components/toast.component';
import { LiqChartsModule } from './common/components/charts/liq-charts.module';
import { LiqDoc360Component } from './common/components/doc360.component';
import { H } from 'highlight.run';

const environmentConfig = {
    'test': 'testing',
    'stage': 'stage',
    'dash': 'stage'
};
const environment = Object.keys(environmentConfig).find(key => window.location.hostname.includes(key)) || 'production';

if (!['localhost', '127.0.0.1'].includes(location.hostname)) {
    H.init('56gl9g91', {
        environment: environment,
        version: 'commit:abcdefg12345',
        backendUrl: 'https://portal.libraryiq.com/log/public',
        networkRecording: {
            enabled: true,
            recordHeadersAndBody: true,
            urlBlocklist: [
                // insert full or partial urls that you don't want to record here
                // Out of the box, Highlight will not record these URLs (they can be safely removed):
                'https://www.googleapis.com/identitytoolkit',
                'https://securetoken.googleapis.com'
            ]
        }
    });
}
@NgModule({
    declarations: [AppComponent],
    imports: [
        ToastrModule.forRoot({
            timeOut: 2000,
            preventDuplicates: true,
            closeButton: true,
            toastComponent: LibraryIQToastComponent
        }),
        RouterModule,
        BrowserModule,
        A11yModule,
        MatSelectModule,
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        CommonDirectivesModule,
        ScrollingModule,
        NgxDaterangepickerMd.forRoot(),
        TranslocoRootModule,
        AngularSvgIconModule.forRoot(),
        LiqChartsModule,
        LiqDoc360Component
    ],
    bootstrap: [AppComponent],
    exports: [MaterialModule],
    providers: [
        DatePipe,
        { provide: APP_BASE_HREF, useValue: '/' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ]
})
export class AppModule {}
