
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {LicenseManager} from 'ag-grid-enterprise';
// eslint-disable-next-line max-len
LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-052267}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{LS&s_and_American_Library_Association}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{LibraryIQ}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{LibraryIQ}_need_to_be_licensed___{LibraryIQ}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{22_February_2025}____[v3]_[01]_MTc0MDE4MjQwMDAwMA==f6eb5105ca7a66fc781b7375555f9fb0');
if (environment.production) {
    enableProdMode();
}
platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
