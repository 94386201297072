export default {
    time: {
        month: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ],
        monthAbbr: [
            'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
            'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
        ],
        dayOfWeek: [
            'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'
        ],
        dayOfWeekAbbr: [
            'Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'
        ]
    },
    legend: {
        selector: {
            all: 'Todas',
            inverse: 'Inversa'
        }
    },
    toolbox: {
        brush: {
            title: {
                rect: 'Selección de cuadro',
                polygon: 'Selección de lazo',
                lineX: 'Seleccionar horizontalmente',
                lineY: 'Seleccionar verticalmente',
                keep: 'Mantener selección',
                clear: 'Despejar selecciones'
            }
        },
        dataView: {
            title: 'Ver datos',
            lang: ['Ver datos', 'Cerrar', 'Actualizar']
        },
        dataZoom: {
            title: {
                zoom: 'Zoom',
                back: 'Restablecer zoom'
            }
        },
        magicType: {
            title: {
                line: 'Cambiar a gráfico de líneas',
                bar: 'Cambiar a gráfico de barras',
                stack: 'Pila',
                tiled: 'Teja'
            }
        },
        restore: {
            title: 'Restaurar'
        },
        saveAsImage: {
            title: 'Guardar como imagen',
            lang: ['Clic derecho para guardar imagen']
        }
    },
    series: {
        typeNames: {
            pie: 'Gráfico circular',
            bar: 'Gráfico de barras',
            line: 'Gráfico de líneas',
            scatter: 'Gráfico de dispersión',
            effectScatter: 'Gráfico de dispersión de ondas',
            radar: 'Gráfico de radar',
            tree: 'Árbol',
            treemap: 'Mapa de árbol',
            boxplot: 'Gráfico de caja',
            candlestick: 'Vela',
            k: 'Gráfico de líneas K',
            heatmap: 'Mapa de calor',
            map: 'Mapa',
            parallel: 'Mapa de coordenadas paralelas',
            lines: 'Gráfico de líneas',
            graph: 'Gráfico de relaciones',
            sankey: 'Diagrama de Sankey',
            funnel: 'Gráfico de embudo',
            gauge: 'Indicador',
            pictorialBar: 'Barra pictórica',
            themeRiver: 'Mapa de río temático',
            sunburst: 'Rayo solar'
        }
    },
    aria: {
        general: {
            withTitle: 'Este es un gráfico sobre "{title}"',
            withoutTitle: 'Este es un gráfico'
        },
        series: {
            single: {
                prefix: '',
                withName: ' con tipo {seriesType} llamado {seriesName}.',
                withoutName: ' con tipo {seriesType}.'
            },
            multiple: {
                prefix: '. Consiste en {seriesCount} recuento de series.',
                withName: 'La serie {seriesId} es un {seriesType} que representa a {seriesName}.',
                withoutName: 'La serie {seriesId} es un {seriesType}.',
                separator: {
                    middle: '',
                    end: ''
                }
            }
        },
        data: {
            allData: 'Los datos son los siguientes: ',
            partialData: 'Los primeros elementos de {displayCnt} son: ',
            withName: 'Los datos de {name} son {value}',
            withoutName: '{value}',
            separator: {
                middle: ', ',
                end: '. '
            }
        }
    }
};
