import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ReportService } from './report.service';
import {catchError} from 'rxjs/operators';

export type  GetTitlesOrdered ={
    expired: boolean;
    data:    TitlesOrdered[];
};

export type TitlesOrdered = {
    DestOrg:      string;
    PONumber:     string;
    CreationDate: string;
    Author:       null | string;
    Title:        string;
    ISBN_ISSN:    null | string;
    QuantOrd:     number;
    CName:        string;
    SupplierName: string;
    FName:        string;
};

export type GetInvoice ={
    expired: boolean;
    data:    InvoiceRow[];
};

export type InvoiceRow =  {
    PaymentStatus:        string;
    BrowseTitle:          string;
    BrowseAuthor:         string;
    ISBN_ISSN:            string;
    InvNumber:            string;
    InvLINumber:          number;
    PONumber:             string;
    LineNumber:           number;
    OrgName:              string;
    InvLISegmentNumber:   number;
    QuantRec:             number;
    Fund:                 string;
    FY:                   string;
    GrandTotalBase:       number;
    InvLineItemSegmentID: number;
    Amount:               number;
    PaymentStatusDate:    string;
    Name:                 string;
};

export interface GetOpenPOS {
    expired: boolean;
    data:    PO[];
}

export interface PO {
    PONumber:       string;
    PONumberSuf:    string;
    name:           string;
    OrderType:      string;
    linenumber:     number;
    OrderStatus:    string;
    TitleOrderedAs: string;
    isbn_issn:      null | string;
    Status:         string;
    QuantOrd:       number;
    Fund:           string;
    FY:             string;
    Amount:         number;
    creationdate:   string;
}


export interface GetAllInvoices {
    expired: boolean;
    data:    Invoices[];
}

export interface Invoices {
    InvoiceID:      number;
    CreationDate:   string;
    OName:          string;
    InvNumber:      string;
    Suffix:         string;
    Type:           string;
    Status:         string;
    StatusID:       number;
    Date:           string;
    Supplier:       string;
    ServCharge:     number;
    AddCharge:      number;
    ShipCharge:     number;
    SalesTax:       number;
    MaterialCharge: number;
    Total:          number;
    NumberOfLines:  number;
}

@Injectable({
    providedIn: 'root'
})

export class AcquisitionService {


    constructor(private reportService: ReportService,
        private http: HttpClient) {

    }
    // any handles error    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private handleError(error: Response | any): any  {
        console.error('ApiService::handleError', error);
        return throwError(error);
    }


    public getOpenPOs(startDate: string,endDate: string): Observable<GetOpenPOS> {
        return this.http
            .get<GetOpenPOS>('/api/acquisition/getOpenPOs', {
            params: {
                library: this.reportService.currentLibrary,
                branch: this.reportService.currentBranch,
                startDate:startDate,
                endDate:endDate
            }
        })
            .pipe<any>(
            catchError(this.handleError)
        );
    }

    public getTitlesOrdered(startDate: string,endDate: string): Observable<GetTitlesOrdered> {
        return this.http
            .get<GetTitlesOrdered>('/api/acquisition/getTitlesOrdered', {
            params: {
                library: this.reportService.currentLibrary,
                branch: this.reportService.currentBranch,
                startDate:startDate,
                endDate:endDate
            }
        })
            .pipe<any>(
            catchError(this.handleError)
        );
    }

    public getAllInvoices(startDate: string,endDate: string): Observable<GetAllInvoices> {
        return this.http
            .get<GetAllInvoices>('/api/acquisition/getAllInvoices', {
            params: {
                library: this.reportService.currentLibrary,
                branch: this.reportService.currentBranch,
                startDate:startDate,
                endDate:endDate
            }
        })
            .pipe<any>(
            catchError(this.handleError)
        );
    }

    public getInvoice(invNumber): Observable<GetInvoice> {
        return this.http
            .get<GetInvoice>('/api/acquisition/getInvoice', {
            params: {
                library: this.reportService.currentLibrary,
                branch: this.reportService.currentBranch,
                invnumber: invNumber
            }
        })
            .pipe<any>(
            catchError(this.handleError)
        );
    }



}
