import { isDevMode, NgModule } from '@angular/core';
import { provideTransloco, TranslocoModule } from '@jsverse/transloco';

import { TranslocoLoaderService } from '../services/transloco-loader.service';

@NgModule({
    exports: [ TranslocoModule ],
    providers: [
        provideTransloco({
            config: {
                availableLangs: ['en', 'es', 'sv'],
                fallbackLang: 'en',
                defaultLang: 'en',
                reRenderOnLangChange: true,
                missingHandler: {
                    // will cause transloco to also download the fallback as well as the
                    // selected, but will not log that it is missing. it might be good to
                    // set to false at some point and check for missing keys.
                    useFallbackTranslation: !isDevMode()
                },
                prodMode: !isDevMode()
            },
            loader: TranslocoLoaderService
        })
    ]
})
export class TranslocoRootModule {}
