import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { Toast } from 'ngx-toastr';
  
@Component({
    selector: '[liq-toast-component]',
    template: `
        <button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title | transloco">
            {{ title | transloco }} <ng-container *ngIf="duplicatesCount">[{{ duplicatesCount + 1 }}]</ng-container>
        </div>
        <div *ngIf="message && options.enableHtml" role="alert" [class]="options.messageClass" [innerHTML]="message">
        </div>
        <div *ngIf="message && !options.enableHtml" role="alert" [class]="options.messageClass" [attr.aria-label]="message | transloco">
            {{ message | transloco }}
        </div>
        <div *ngIf="options.progressBar">
            <div class="toast-progress" [style.width]="width + '%'"></div>
        </div>
    `,
    animations: [
        trigger('flyInOut', [
            state('inactive', style({ opacity: 0 })),
            state('active', style({ opacity: 1 })),
            state('removed', style({ opacity: 0 })),
            transition('inactive => active', animate('{{ easeTime }}ms {{ easing }}')),
            transition('active => removed', animate('{{ easeTime }}ms {{ easing }}'))
        ])
    ],
    preserveWhitespaces: false,
    standalone: true,
    imports: [NgIf, TranslocoModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryIQToastComponent extends Toast {}
