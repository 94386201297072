import { NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';

import chartsEnglish from './i18n/en';
import chartsSpanish from './i18n/es';
import chartsSwedish from './i18n/sv';

@NgModule({
    imports: [
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts').then(echarts => {
                echarts.registerLocale('en', chartsEnglish);
                echarts.registerLocale('es', chartsSpanish);
                echarts.registerLocale('sv', chartsSwedish);
                return echarts;
            })
        })
    ]
})
export class LiqChartsModule {}
