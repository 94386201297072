import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ReportService } from './report.service';
import {catchError} from 'rxjs/operators';

export interface GetBudgetSpend {
    expired: boolean;
    data:    BudgetSpend[];
}

export interface BudgetSpend {
    id:                  number;
    libraryid:           number;
    branchid:            number;
    ParentFundID:        number;
    TopCategory:         string;
    FundID:              number;
    FundOwner:           string;
    FiscalYear:          string;
    ParentFundName:      string;
    FundName:            string;
    FundType:            string;
    BeginningAllocation: string;
    CurrentAllocation:   string;
    Encumbered:          string;
    Spent:               string;
    FreeBalance:         string;
    PercentExpended:     number;
    insert_date:         string;
    PercentBudgeted?: string;
}


interface BudgetSpendQuarter {
    currentallocation: number;
    encumbered: number;
    freebalance: number;
    spent: number;
}
interface FullBudgetSpend {
    q1: BudgetSpendQuarter;
    q2: BudgetSpendQuarter;
    q3: BudgetSpendQuarter;
    q4: BudgetSpendQuarter;
}

export interface GetBudgetSpendByQuarter {
    expired: boolean;
    data:    BudgetSpendByQuarter[];
}

export interface BudgetSpendByQuarter {
    Quarter:           string;
    topcategory:       string;
    insert_date:       string;
    currentallocation: number;
    encumbered:        number;
    spent:             number;
    freebalance:       number;
}



@Injectable({
    providedIn: 'root'
})

export class BudgetSpendService {
    budgetData: FullBudgetSpend = {
        q1: {
            currentallocation: 0,
            encumbered: 0,
            freebalance: 0,
            spent: 0
        },
        q2: {
            currentallocation: 0,
            encumbered: 0,
            freebalance: 0,
            spent: 0
        }, 
        q3: {
            currentallocation: 0,
            encumbered: 0,
            freebalance: 0,
            spent: 0
        },
        q4: {
            currentallocation: 0,
            encumbered: 0,
            freebalance: 0,
            spent: 0
        }
    };

    constructor(private reportService: ReportService,
        private http: HttpClient) {

    }

    // any handles error    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private handleError(error: Response | any): any {
        console.error('ApiService::handleError', error);
        return throwError(error);
    }

    public clearBudgetData(): void {
        this.budgetData.q1.currentallocation = 0;
        this.budgetData.q1.encumbered = 0;
        this.budgetData.q1.freebalance = 0;
        this.budgetData.q1.spent = 0;
        this.budgetData.q2.currentallocation = 0;
        this.budgetData.q2.encumbered = 0;
        this.budgetData.q2.freebalance = 0;
        this.budgetData.q2.spent = 0;
        this.budgetData.q3.currentallocation = 0;
        this.budgetData.q3.encumbered = 0;
        this.budgetData.q3.freebalance = 0;
        this.budgetData.q3.spent = 0;
        this.budgetData.q4.currentallocation = 0;
        this.budgetData.q4.encumbered = 0;
        this.budgetData.q4.freebalance = 0;
        this.budgetData.q4.spent = 0;
    }

    public getBudgetSpend(): Observable<GetBudgetSpend> {
        return this.http
            .get<GetBudgetSpend>('/api/budgetSpend/getBudgetSpend', {
            params: {
                library: this.reportService.currentLibrary,
                branch: this.reportService.currentBranch
            }
        })
            .pipe<any>(
            catchError(this.handleError)
        );
    }

    public getBudgetSpendQuarter(q1: string, q2: string, q3: string, q4: string): Observable<GetBudgetSpendByQuarter> {
        let branch;
        branch = this.reportService.currentBranch;
        if (this.reportService.branches.length===1) branch='ALL';
        return this.http
            .get<GetBudgetSpendByQuarter>('/api/budgetSpend/getBudgetSpendByQuarter', {
            params: {
                library: this.reportService.currentLibrary,
                branch: branch,
                q1: q1,
                q2: q2,
                q3: q3,
                q4: q4
            }
        })
            .pipe<any>(
            catchError(this.handleError)
        );
    }




}
