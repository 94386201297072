import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {ReportService} from './report.service';
import {catchError} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})

export class ReportManagementService {
  

    constructor( 
        private reportService: ReportService,
        private http: HttpClient) {

    }
    // any handles error    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private handleError(error: Response | any): any {
        console.error('ApiService::handleError', error);
        return throwError(() => new Error(error));
    }


    public getAllReports(): Observable<any> {
        return this.http
            .get<any>('/api/reportManagement/getAllReports', {
            params: {
                id: this.reportService.currentLibrary
            }
        })
            .pipe<any>(
            catchError(this.handleError)
        );
    }

    public deleteFile(file): Observable<any> {
        return this.http
            .post<any>('/api/reportManagement/deleteFile',  {
            file            
        })
            .pipe<any>(
            catchError(this.handleError)
        );
    }

}
