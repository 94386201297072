import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpEventType,
    HttpErrorResponse,
    HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './common/services/auth.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService,  private toastrService: ToastrService) { }
    intercept(request: HttpRequest<{}>, next: HttpHandler): Observable<HttpEvent<HttpEventType>> {
        request = request.clone({
            setHeaders: {
                'x-session-token': `${this.auth.apiToken}`
            }
        });
        return next.handle(request).pipe(tap(() => {
            //  debugger;
        }, (err: HttpErrorResponse) => {
            if (err instanceof HttpErrorResponse) {
                // console.log(err);
                if (err.status === 419) {
                    localStorage.removeItem('currentLibrary'+this.auth.user.id);
                    localStorage.removeItem('currentBranch' + this.auth.user.id);
                    this.auth.logout();
                }
                if (err.status === 429) {
                    this.toastrService.error('Multiple Request for same report.  Wait a few minutes for previous query to complete');
                    this.auth.redirectToHome();
                }
            }
        }));
    }
}
